import { template as template_1652d158b09a4202acd0963cceb39b5f } from "@ember/template-compiler";
const FKControlMenuContainer = template_1652d158b09a4202acd0963cceb39b5f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
