import { template as template_fea02ad0c5124f00bead7a92fd5f7f43 } from "@ember/template-compiler";
const FKText = template_fea02ad0c5124f00bead7a92fd5f7f43(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
